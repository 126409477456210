import React from "react";
import { Menu, MenuItem, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const IconMenu = ({
    anchorEl,
    open,
    onClose,
    backgroundColor,
    icon,
    iconTitle,
    onMenuItemClick,
}) => {
    // Safely handle the event in the onClose function
    const handleClose = (event) => {
        if (onClose) {
            onClose(event); // Pass event to onClose
        }
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose} // Always use the event-safe version
            MenuListProps={{
                "aria-labelledby": "icon-button",
                sx: {
                    backgroundColor: backgroundColor,
                    color: "#fff",
                    width: "280px",
                    borderRadius: "5px",
                },
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {icon}
                <Typography variant="h6" sx={{ padding: "10px" }}>
                    {iconTitle}
                </Typography>
            </Box>
            <Box>
                {iconTitle === "Product Overview" ? (
                    <>
                        <MenuItem
                            onClick={() => {
                                window.location.href = "https://specifyingpdf.protector.com.au/";
                            }}
                        >
                            Download Design PDF
                        </MenuItem>
                        <MenuItem component={Link} to="/xtr">
                            Xtream Calculator
                        </MenuItem>
                        <MenuItem onClick={handleClose}>Option 2</MenuItem>
                        <MenuItem onClick={handleClose}>Option 3</MenuItem>
                    </>
                ) : (
                    <>
                        <MenuItem onClick={handleClose}>Option 1</MenuItem>
                        <MenuItem onClick={handleClose}>Option 2</MenuItem>
                        <MenuItem onClick={handleClose}>Option 3</MenuItem>
                    </>
                )}
            </Box>
        </Menu>
    );
};

export default IconMenu;
