import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Grid,
    Box,
    Typography,
    Button,
    Paper,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import NavBar from "../Components/navBar";
import apiClient from "../Helpers/apiClient";

const DashBoardPage = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        email: "",
        firstName: "",
        lastName: "",
    });

    useEffect(() => {
        const email = localStorage.getItem("email");
        const firstName = localStorage.getItem("firstName");
        const lastName = localStorage.getItem("lastName");
        setUserData({ email, firstName, lastName });
    }, []);

    const handleLogout = async () => {
        try {
            await apiClient.post("/api/Account/logout");
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            localStorage.removeItem("firstName");
            localStorage.removeItem("lastName");
            navigate("/");
        } catch (error) {
            console.error("Logout failed", error);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                backgroundColor: "#f5f5f5",
            }}
        >
            <NavBar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
                <Grid container spacing={3}>
                    {/* Header */}
                    <Grid item xs={12}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant="h4" component="h1">
                                Dashboard
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleLogout}
                                startIcon={<ExitToAppIcon />}
                            >
                                Logout
                            </Button>
                        </Paper>
                    </Grid>

                    {/* Welcome Message */}
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Typography variant="h5" component="h2" gutterBottom>
                                {userData.firstName && userData.lastName
                                    ? `Welcome back, ${userData.firstName} ${userData.lastName}!`
                                    : "Welcome to your dashboard!"}
                            </Typography>
                        </Paper>
                    </Grid>

                    {/* User Info */}
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                <PersonIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                                User Information
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <BadgeIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={userData.firstName}
                                        secondary="First Name"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <BadgeIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={userData.lastName}
                                        secondary="Last Name"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <EmailIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={userData.email} secondary="Email" />
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default DashBoardPage;
