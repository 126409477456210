// routes/AppRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../Pages/homePage";
import AboutPage from "../Pages/aboutPage";
import PlayPage from "../Pages/playPage";
import SignupPage from "../Pages/signupPage";
import DashBoardPage from "../Pages/dashBoardPage";
import EmailConfirmationPage from "../Pages/emailConfirmationPage";
import XtrCalculatorPage from "../Pages/xtrCalculatorPage";

function AppRoutes() {
    return (
        <Routes>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/play" element={<PlayPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/dashboard" element={<DashBoardPage />} />
            <Route path="/xtr" element={<HomePage />} />
            <Route path="/confirmation-success" element={<EmailConfirmationPage />} />
            <Route path="/" element={<XtrCalculatorPage />} />
            <Route path="*" element={<h1>404 Not Found</h1>} />

            {/* <Route path="/contact" element={<Contact />} /> */}
        </Routes>
    );
}

export default AppRoutes;
