import React, { useState, useRef, useEffect } from "react";
import {
    IconButton,
    Box,
    TextField,
    Button,
    Typography,
    Snackbar,
    ClickAwayListener,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../Helpers/apiClient"; // Adjust the import path as needed

const DropdownLoginForm = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [error, setError] = useState("");
    const dropdownRef = useRef(null);
    const firstInputRef = useRef(null);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiClient.post("/api/Account/login", formData);
            if (response.data && response.data.token) {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("email", response.data.email);
                localStorage.setItem("firstName", response.data.firstName);
                localStorage.setItem("lastName", response.data.lastName);
                handleClose();
                navigate("/dashboard");
            }
        } catch (error) {
            setError(error.response?.data?.message || "Login failed. Please try again.");
        }
    };

    useEffect(() => {
        if (open && firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [open]);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Box sx={{ position: "relative" }}>
                <IconButton color="inherit" onClick={handleToggle}>
                    <AccountCircleIcon />
                </IconButton>

                {open && (
                    <Box
                        ref={dropdownRef}
                        sx={{
                            position: "absolute",
                            right: 0,
                            top: "100%",
                            width: "350px",
                            backgroundColor: "#2d3b56",
                            color: "#fff",
                            borderRadius: "10px",
                            padding: "20px",
                            zIndex: 1000,
                            boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
                        }}
                    >
                        <Box component="form" onSubmit={handleSubmit} sx={{ padding: "10px" }}>
                            <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                                <AccountCircleIcon sx={{ fontSize: 40, color: "#fff" }} />
                                <Typography variant="h6" sx={{ color: "#fff" }}>
                                    Login
                                </Typography>
                            </Box>

                            <Typography variant="body2" sx={{ color: "#fff", marginBottom: "8px" }}>
                                Email
                            </Typography>
                            <TextField
                                fullWidth
                                name="email"
                                variant="outlined"
                                value={formData.email}
                                onChange={handleChange}
                                inputRef={firstInputRef}
                                InputProps={{
                                    sx: {
                                        backgroundColor: "#fff",
                                        color: "#000",
                                        height: "40px",
                                        padding: "0",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#58627b",
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#8da0b3",
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#fff",
                                        },
                                    },
                                }}
                                sx={{ marginBottom: "15px" }}
                            />

                            <Typography variant="body2" sx={{ color: "#fff", marginBottom: "8px" }}>
                                Password
                            </Typography>
                            <TextField
                                fullWidth
                                name="password"
                                type="password"
                                variant="outlined"
                                value={formData.password}
                                onChange={handleChange}
                                InputProps={{
                                    sx: {
                                        backgroundColor: "#fff",
                                        color: "#000",
                                        height: "40px",
                                        padding: "0",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#58627b",
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#8da0b3",
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#fff",
                                        },
                                    },
                                }}
                                sx={{ marginBottom: "15px" }}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    backgroundColor: "#58627b",
                                    color: "#fff",
                                    marginBottom: "15px",
                                }}
                            >
                                Log In
                            </Button>

                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: "#58627b", color: "#fff" }}
                                >
                                    <Link
                                        to="./signup"
                                        style={{ textDecoration: "none", color: "inherit" }}
                                    >
                                        Register
                                    </Link>
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: "#58627b", color: "#fff" }}
                                >
                                    Lost Password
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                )}

                <Snackbar
                    open={!!error}
                    autoHideDuration={6000}
                    onClose={() => setError("")}
                    message={error}
                />
            </Box>
        </ClickAwayListener>
    );
};

export default DropdownLoginForm;
