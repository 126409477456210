import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Box, IconButton, Modal, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import FolderIcon from "@mui/icons-material/Folder";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import BuildIcon from "@mui/icons-material/Build";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExpandableBox from "./navBar/ExpandableBox";
import DropdownLoginForm from "./dropdownLoginForm";

function NavBar() {
    const [expandedBox, setExpandedBox] = useState(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const toggleBox = (boxName) => {
        setExpandedBox(expandedBox === boxName ? null : boxName);
    };

    const handleMobileMenuToggle = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    useEffect(() => {
        if (!isMobile) {
            setMobileMenuOpen(false);
        }
    }, [isMobile]);

    const renderExpandableBoxes = () => (
        <Box
            sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: "15px",
                width: isMobile ? "100%" : "auto",
            }}
        >
            <ExpandableBox
                title="EcoProtector"
                backgroundColor="#85724d"
                expanded={expandedBox === "kingflor"}
                onClick={() => toggleBox("kingflor")}
                icons={[
                    <InfoIcon />,
                    <SettingsApplicationsIcon />,
                    <ArchitectureIcon />,
                    <BuildIcon />,
                    <FolderIcon />,
                ]}
                iconTitles={[
                    "Product Overview",
                    "Product Specifications",
                    "Design Data",
                    "Installation Data",
                    "Case Studies",
                ]}
            />
            <ExpandableBox
                title="XtreamProtector"
                backgroundColor="#3384ae"
                expanded={expandedBox === "roofWall"}
                onClick={() => toggleBox("roofWall")}
                icons={[
                    <InfoIcon />,
                    <SettingsApplicationsIcon />,
                    <ArchitectureIcon />,
                    <BuildIcon />,
                    <FolderIcon />,
                ]}
                iconTitles={[
                    "To be determined",
                    "To be determined",
                    "To be determined",
                    "To be determined",
                    "To be determined",
                ]}
            />
            <ExpandableBox
                title="StormBrake"
                backgroundColor="#6e6f71"
                expanded={expandedBox === "finesse"}
                menu
                onClick={() => toggleBox("finesse")}
                icons={[<InfoIcon />, <SettingsIcon />, <BuildIcon />]}
                iconTitles={["To be determined", "To be determined", "To be determined"]}
            />
            <ExpandableBox
                title="TRAP-IT"
                backgroundColor="#486e84"
                expanded={expandedBox === "aramax"}
                onClick={() => toggleBox("aramax")}
                icons={[<InfoIcon />, <SettingsIcon />, <MenuBookIcon />]}
                iconTitles={["To be determined", "To be determined", "To be determined"]}
            />
            <ExpandableBox
                title="ENVIROSAVE"
                backgroundColor="#f28625"
                expanded={expandedBox === "freeform"}
                onClick={() => toggleBox("freeform")}
                icons={[<InfoIcon />, <SettingsIcon />, <BuildIcon />]}
                iconTitles={["To be determined", "To be determined", "To be determined"]}
            />
            <ExpandableBox
                title="TORNADO-GPT"
                backgroundColor="#4d5d7c"
                expanded={expandedBox === "toolsResources"}
                onClick={() => toggleBox("toolsResources")}
                icons={[<InfoIcon />, <SettingsIcon />, <MenuBookIcon />]}
                iconTitles={["To be determined", "To be determined", "To be determined"]}
            />
        </Box>
    );

    const renderRightSectionIcons = () => (
        <Box
            sx={{
                display: "flex",
                backgroundColor: "#4d5d7c",
                borderRadius: "5px",
                marginLeft: "2vw",
            }}
        >
            <IconButton color="inherit">
                <SearchIcon />
            </IconButton>
            <IconButton color="inherit">
                <StarIcon />
            </IconButton>
            <IconButton color="inherit">
                <ChatBubbleIcon />
            </IconButton>
            <DropdownLoginForm />
        </Box>
    );

    return (
        <AppBar
            position="static"
            sx={{
                backgroundColor: "#2a3a5b",
                padding: "0px",
                marginBottom: "17px",
                height: "80px",
            }}
        >
            <Toolbar
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                {/* Logo */}
                <a href="https://protector.com.au/" target="_blank" rel="noopener noreferrer">
                    <Box>
                        <img
                            src="/imgs/Protector-Text-Black-1.png"
                            alt="Fielders Logo"
                            style={{ height: "18px" }}
                        />
                    </Box>
                </a>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* Hamburger menu for mobile */}
                    {isMobile && (
                        <IconButton
                            color="inherit"
                            onClick={handleMobileMenuToggle}
                            sx={{ marginRight: 1 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}

                    {/* Display expandable boxes for non-mobile screens */}
                    {!isMobile && renderExpandableBoxes()}

                    {/* Always display the right section icons */}
                    {renderRightSectionIcons()}
                </Box>
            </Toolbar>

            {/* Mobile Menu Modal */}
            <Modal
                open={mobileMenuOpen}
                onClose={handleMobileMenuToggle}
                aria-labelledby="mobile-menu-modal"
                aria-describedby="mobile-menu-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxHeight: "90%",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        overflowY: "auto",
                    }}
                >
                    <IconButton
                        sx={{ position: "absolute", right: 8, top: 8 }}
                        onClick={handleMobileMenuToggle}
                    >
                        <CloseIcon />
                    </IconButton>
                    {renderExpandableBoxes()}
                </Box>
            </Modal>
        </AppBar>
    );
}

export default NavBar;
